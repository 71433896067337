.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.bottom-4 {
  bottom: 1rem;
}

.left-4 {
  left: 1rem;
}

.left-7 {
  left: 1.75rem;
}

.right-4 {
  right: 1rem;
}

.top-12 {
  top: 3rem;
}

.top-3\.5 {
  top: .875rem;
}

.top-4 {
  top: 1rem;
}

.top-6 {
  top: 1.5rem;
}

.top-8 {
  top: 2rem;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[-1\.00px\] {
  margin-top: -1px;
}

.mt-auto {
  margin-top: auto;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-28 {
  height: 7rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-\[10\.83px\] {
  height: 10.83px;
}

.h-\[120px\] {
  height: 120px;
}

.h-\[12px\] {
  height: 12px;
}

.h-\[15px\] {
  height: 15px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[72px\] {
  height: 72px;
}

.h-\[9\.6px\] {
  height: 9.6px;
}

.h-auto {
  height: auto;
}

.h-px {
  height: 1px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-4 {
  width: 1rem;
}

.w-6 {
  width: 1.5rem;
}

.w-\[13\.12px\] {
  width: 13.12px;
}

.w-\[160px\] {
  width: 160px;
}

.w-\[18\.83px\] {
  width: 18.83px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[80px\] {
  width: 80px;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-\[1440px\] {
  max-width: 1440px;
}

.flex-\[2\] {
  flex: 2;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[9px\] {
  gap: 9px;
}

.self-stretch {
  align-self: stretch;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-\[0\.6px\] {
  border-width: .6px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-line {
  border-color: var(--line);
}

.border-primary-1 {
  border-color: var(--primary-1);
}

.bg-\[\#e1e5f4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(225 229 244 / var(--tw-bg-opacity));
}

.bg-\[\#ffdada\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 218 218 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-primary-1 {
  background-color: var(--primary-1);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.object-cover {
  object-fit: cover;
}

.p-2\.5 {
  padding: .625rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-\[8px\] {
  font-size: 8px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-\[18\.0px\] {
  line-height: 18px;
}

.leading-\[26\.1px\] {
  line-height: 26.1px;
}

.leading-\[26px\] {
  line-height: 26px;
}

.leading-\[normal\] {
  line-height: normal;
}

.tracking-\[0\] {
  letter-spacing: 0;
}

.tracking-\[1\.60px\] {
  letter-spacing: 1.6px;
}

.tracking-\[2\.40px\] {
  letter-spacing: 2.4px;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-\[\#222222\] {
  --tw-text-opacity: 1;
  color: rgb(34 34 34 / var(--tw-text-opacity));
}

.text-\[\#2e3961\] {
  --tw-text-opacity: 1;
  color: rgb(46 57 97 / var(--tw-text-opacity));
}

.text-\[\#333333\] {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
}

.text-\[\#606969\] {
  --tw-text-opacity: 1;
  color: rgb(96 105 105 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-heading-1 {
  color: var(--heading-1);
}

.text-neutral-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.text-primary-1 {
  color: var(--primary-1);
}

.text-secondary-2 {
  color: var(--secondary-2);
}

.text-smallfont {
  color: var(--smallfont);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.shadow-\[0px_0px_28px_\#0000000f\] {
  --tw-shadow: 0px 0px 28px #0000000f;
  --tw-shadow-colored: 0px 0px 28px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\[font-family\:\'Mohave\'\,Helvetica\] {
  font-family: Mohave, Helvetica;
}

:root {
  --heading-1: #2e3a62;
  --line: #cacaca;
  --primary-1: #28304c;
  --secondary-2: #f8cd8c;
  --smallfont: #5a5a5a;
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

@media (width >= 768px) {
  .md\:left-auto {
    left: auto;
  }

  .md\:right-4 {
    right: 1rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:flex {
    display: flex;
  }

  .md\:inline-flex {
    display: inline-flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-10 {
    height: 2.5rem;
  }

  .md\:h-40 {
    height: 10rem;
  }

  .md\:h-5 {
    height: 1.25rem;
  }

  .md\:h-6 {
    height: 1.5rem;
  }

  .md\:h-8 {
    height: 2rem;
  }

  .md\:h-\[160px\] {
    height: 160px;
  }

  .md\:h-\[168px\] {
    height: 168px;
  }

  .md\:h-\[19\.65px\] {
    height: 19.65px;
  }

  .md\:h-\[26\.67px\] {
    height: 26.67px;
  }

  .md\:h-\[460px\] {
    height: 460px;
  }

  .md\:w-10 {
    width: 2.5rem;
  }

  .md\:w-5 {
    width: 1.25rem;
  }

  .md\:w-8 {
    width: 2rem;
  }

  .md\:w-\[120\.29px\] {
    width: 120.29px;
  }

  .md\:w-\[180px\] {
    width: 180px;
  }

  .md\:w-\[200px\] {
    width: 200px;
  }

  .md\:w-\[243px\] {
    width: 243px;
  }

  .md\:w-\[26\.67px\] {
    width: 26.67px;
  }

  .md\:w-\[420px\] {
    width: 420px;
  }

  .md\:w-\[460px\] {
    width: 460px;
  }

  .md\:w-\[760px\] {
    width: 760px;
  }

  .md\:w-\[800px\] {
    width: 800px;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:gap-\[14\.4px\] {
    gap: 14.4px;
  }

  .md\:gap-\[60px\] {
    gap: 60px;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:px-\[100px\] {
    padding-left: 100px;
    padding-right: 100px;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-\[10px\] {
    font-size: 10px;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1024px) {
  .lg\:right-4 {
    right: 1rem;
  }

  .lg\:h-10 {
    height: 2.5rem;
  }

  .lg\:h-6 {
    height: 1.5rem;
  }

  .lg\:h-8 {
    height: 2rem;
  }

  .lg\:h-\[200px\] {
    height: 200px;
  }

  .lg\:h-\[632px\] {
    height: 632px;
  }

  .lg\:w-10 {
    width: 2.5rem;
  }

  .lg\:w-6 {
    width: 1.5rem;
  }

  .lg\:w-\[260px\] {
    width: 260px;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-\[15px\] {
    font-size: 15px;
  }

  .lg\:text-\[19\.2px\] {
    font-size: 19.2px;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
/*# sourceMappingURL=index.2ddb8147.css.map */
